<template>
  <Teleport v-if="resumableState && isMobile" to="#mobile-bar-before">
    <div
      class="flex border-b border-b-gray-200 border-t-4 border-t-primary-500"
    >
      <button
        class="px-10 py-8 font-bold text-left flex gap-8 items-center flex-1 hover:bg-gray-100"
        @click.prevent="onClick"
      >
        <SpriteSymbol name="cta-circle" class="size-16" />
        <span>{{ title }}</span>
      </button>
      <button
        class="aspect-square w-40 ml-auto flex items-center justify-center hover:bg-gray-100"
        title="Bestellung abbrechen"
        @click.prevent="clear"
      >
        <SpriteSymbol name="cross" class="w-12 aspect-square" />
      </button>
    </div>
  </Teleport>
  <div v-else-if="resumableState" class="fixed bottom-0 left-0 w-full z-50">
    <div class="md:layout-grid">
      <div class="col-start-1 col-end-8 lg:col-end-6 relative">
        <div
          class="absolute bottom-0 left-0 w-full bg-white shadow-invert flex border-t-6 border-t-primary-500"
        >
          <button
            class="px-16 py-12 font-bold text-left flex gap-8 items-center flex-1 hover:bg-gray-100"
            @click.prevent="onClick"
          >
            <SpriteSymbol name="cta-circle" class="size-20" />
            <span>{{ title }}</span>
          </button>
          <button
            class="aspect-square w-48 ml-auto flex items-center justify-center hover:bg-gray-100"
            title="Bestellung abbrechen"
            @click.prevent="clear"
          >
            <SpriteSymbol name="cross" class="w-12 aspect-square" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const router = useRouter()
const { activeStates, clear } = useWizardState()

const { isMobile } = useViewport()
const { $texts } = useEasyTexts()

const resumableState = computed(() => activeStates.value[0])

function onClick() {
  router.push(resumableState.value.url)
}

const title = computed(() => {
  if (resumableState.value.routeName === 'wizard-consultation-form') {
    return $texts(
      'wizard_continue_consultation_button',
      'Beratungstermin fortsetzen',
    )
  }
  return $texts('wizard_continue_product_order_button', 'Bestellung fortsetzen')
})
</script>
