<template>
  <div class="pb-[88px] md:pb-64 lg:pb-48 relative pt-header">
    <NuxtPageDependency>
      <AppHeader>
        <Transition
          enter-active-class="duration-500 ease-out"
          enter-from-class="opacity-0 -translate-y-64"
          enter-to-class="opacity-100"
          leave-active-class="duration-500 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0 -translate-y-64"
        >
          <Breadcrumb v-if="showBreadcrumb" :links="breadcrumb" />
        </Transition>
      </AppHeader>
    </NuxtPageDependency>
    <ClientOnly>
      <DrupalMessages v-if="!isEditing" />
    </ClientOnly>
    <div>
      <slot />
    </div>
    <AppFooter />
    <AppMobileBar />
    <ClientOnly v-if="!isEditing">
      <AppFollowMenu />
      <AppWizardStates />
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
const drupalUser = useDrupalUser()
const route = useRoute()
const breadcrumb = useBreadcrumbLinks()

const isEditing = computed(
  () =>
    !!(route.query.blokkliEditing || route.query.blokkliPreview) &&
    drupalUser.value.accessToolbar,
)

const showBreadcrumb = computed(
  () =>
    route.meta.hideBreadcrumb !== true &&
    route.path !== '/' &&
    breadcrumb.value.length > 1,
)
</script>
