<template>
  <div class="layout-grid print:hidden">
    <section
      class="relative until-md:h-60 mobile-only:overflow-hidden col-start-2 col-end-[-2] z-50"
    >
      <nav
        class="absolute left-0 w-full top-0 pt-12 overflow-auto"
        aria-label="breadcrumbs"
      >
        <ol
          ref="list"
          itemscope
          itemtype="http://schema.org/BreadcrumbList"
          class="flex whitespace-nowrap items-center"
        >
          <li
            v-for="(link, index) in links"
            :key="index"
            itemprop="itemListElement"
            itemscope
            itemtype="http://schema.org/ListItem"
            class="text-gray-800 after:content-['»'] after:inline-block after:mx-8 after:font-heavy last:after:hidden after:text-xs after:text-gray-700 hover:text-gray-950 flex leading-8"
          >
            <VuepalLink
              itemprop="item"
              :to="link.url.path"
              class="font-bold text-sm text-gray-500 hover:text-gray-900"
              :class="{
                'pointer-events-none': !link.url.path,
              }"
            >
              <SpriteSymbol
                v-if="index === 0"
                name="home"
                class="size-12 inline-block -translate-y-2"
              />
              <div
                itemprop="name"
                :class="{
                  'sr-only': index === 0,
                }"
                v-html="$sup(link.title)"
              />
            </VuepalLink>
            <meta itemprop="position" :content="`${index + 2}`" />
          </li>
        </ol>
      </nav>
    </section>
  </div>
</template>

<script lang="ts" setup>
import type { BreadcrumbFragment } from '#graphql-operations'

defineProps<{
  links: BreadcrumbFragment[]
}>()

const { $sup } = useNuxtApp()
</script>
